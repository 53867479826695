import React from 'react';
import { Row, Col } from 'react-bootstrap';
import elrondWhiteLogo from 'assets/img/egld_white.svg';
// import footerDivPng from 'assets/img/footer-div.png';
import coingeckoLogo from 'assets/img/icons/coingecko.png';
import coinmarketcapLogo from 'assets/img/icons/coinmarketcap.png';
import elrondscanLogo from 'assets/img/icons/elrondscan.png';
// import syncrhonicLogo from 'assets/img/synchronic-W.png';

import CTPLogo from 'assets/img/seed-captain-logo.png';

import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';
import {
  SOCIAL_WEBSITE_URL
} from '../../../config';
import './index.scss';

const Footer = () => {
  return (
    <div>
      {/* <div className="footer-divider">
        <img className="w-100" src={footerDivPng} />
      </div> */}
      <div className="ctp-footer">
        <div className="ctp-container">
          <Row className="align-items-center" style={{ rowGap: '20px' }}>
            <Col sm={6}>
              <div className="d-flex align-items-center">
                <img src={CTPLogo} style={{ height: '50px' }} />
                <span className="token-name ml-2">Seed Captain</span>
              </div>

              {/* <div className="mt-4" style={{ fontSize: '16px' }}>
                {`Everybody stay here and sit tight. We are going to the moon. Our community grows stronger every day. Please follow our social platforms to get the most up-to-date accurate CTP information`}
              </div> */}

              <div className="mt-4" style={{ fontSize: '12px' }}>
                {`Past performances do not indicate future success.
                This web page and any other contents published on this website shall not constitute investment advice, financial advice, trading advice, or any other kind of advice, and you should not treat any of the website's content as such. You alone assume the sole responsibility of evaluating the merits and risks associated with using any information or other content on this website before making any decisions based on such information. You understand that the crypto market is characterised by high volatility, and you should be aware of the concrete possibility of losing the entirety of the funds you allocated in the crypto market. You should refrain from using funds you can't afford to lose when purchasing cryptocurrencies and other digital tokens.`}
              </div>

              <div className="mt-4 d-flex align-items-center">
                <a className="social-link-a" href="https://www.bibox.com/en/exchange/full-screen/CTP_USDT" target="blank">
                  <div className="buy-token-but">
                    Buy $CTP
                  </div>
                </a>
                <span className="ml-4">
                  info@seedcaptain.io
                </span>
              </div>
            </Col>

            <Col sm={6}>
              <div>Explore the link</div>

              <div className="d-flex mt-4" style={{ columnGap: '20px' }}>
                <a className="social-link-a" href=" https://www.coingecko.com/en/coins/captain-planet" target="blank">
                  <div className="d-flex align-items-center">
                    <img src={coingeckoLogo} />
                    <span className="ml-2">CoinGecko</span>
                  </div>
                </a>

                <a className="social-link-a" href="https://coinmarketcap.com/currencies/captain-planet/" target="blank">
                  <div className="d-flex align-items-center">
                    <img src={coinmarketcapLogo} />
                    <span className="ml-2">Coinmarketcap</span>
                  </div>
                </a>

                <a className="social-link-a" href="https://explorer.multiversx.com/tokens/CTP-298075" target="blank">
                  <div className="d-flex align-items-center">
                    <img src={elrondWhiteLogo} width={25} height={25}/>
                    <span className="ml-2">MultiversX Explorer</span>
                  </div>
                </a>
              </div>

              <div className='d-flex align-items-center mt-5'>
                <span className='home-sub-title'>Powered by:</span>
                <img className='ml-2' src={elrondWhiteLogo} width={30} height={30} />
                <span className="ml-2" style={{ fontSize: '20px', color: "white" }}>MultiversX</span>
              </div>


              {/* <div className='d-flex align-items-center mt-5'>
              <span className='home-sub-title'>Sponsored by:</span>
              <div>
                <img className='ml-2' src={syncrhonicLogo} style={{ width: '200px' }} />
              </div>
            </div> */}
            </Col>
          </Row>

          <div className="mt-5 d-flex justify-content-center">
            <a
              {...{
                target: '_blank'
              }}
              className='d-flex align-items-center made-by'
              href={SOCIAL_WEBSITE_URL}
            >
              Made with <HeartIcon className='mx-1' /> by Synchronic Software
            </a>
          </div>
        </div>
      </div >
    </div>
  );
};

export default Footer;
