import { Account, Address, AddressValue, ArgSerializer, BigUIntValue, ContractFunction, OptionalValue, ResultsParser, SmartContract, StringValue, TokenIdentifierValue, TypedValue } from '@multiversx/sdk-core/out';
import { useGetAccountInfo, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { contracts, FireRings, isDev, UsdcTokenId, WaterRings } from 'config';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { convertWeiToEsdt, shortenAddress, shortenEsdt } from 'utils';

import BigNumber from 'bignumber.js/bignumber';
import { logout } from '@multiversx/sdk-dapp/utils';
import { toast } from 'react-toastify';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

export const BigZero = new BigNumber(0);

const SeedInvestors = () => {
  const { account: {address: userAddress, balance} } = useGetAccountInfo();
  const navigate = useNavigate();

  const contractAddress = new Address(contracts.SeedInvestorsSftMint.address);
  const smartContract = new SmartContract({ address: contractAddress, abi: contracts.SeedInvestorsSftMint.abi });

  const { network: { apiAddress } } = useGetNetworkConfig();
  const networkProvider = new ProxyNetworkProvider(apiAddress, { timeout: 10000 });
  const { hasPendingTransactions } = useGetPendingTransactions();

  const [usdcBalance, setUsdcBalance] = useState(BigZero);
  const [firstPhaseStarted, setFirstPhaseStarted] = useState(false);
  const [firstPhaseEnded, setFirstPhaseEnded] = useState(false);
  const [secondPhaseStarted, setSecondPhaseStarted] = useState(false);
  const [secondPhaseEnded, setSecondPhaseEnded] = useState(false);

  const [price, setPrice] = useState(BigZero);
  const [minted, setMinted] = useState(false);
  const [sold, setSold] = useState(0);
  const [remain, setRemain] = useState(0);

  const mintable = ((firstPhaseStarted && !firstPhaseEnded) || (secondPhaseStarted && !secondPhaseEnded)) && usdcBalance.gte(price) && !minted;

  useEffect(() => {
    getUserSaleInfo();

    (async () => {
        if(!userAddress) return;

        try {
            const { data } = await axios.get(`${apiAddress}/accounts/${userAddress}/tokens/${UsdcTokenId}`);

            setUsdcBalance(new BigNumber(data.balance));
        } catch(error) {
            setUsdcBalance(BigZero);
        }
    })();
  }, [hasPendingTransactions]);

  const getUserSaleInfo = () => {

    (async () => {
        try {
            const query = smartContract.createQuery({
            func: new ContractFunction('viewSaleInfo'),
            args: [
              userAddress ? new AddressValue(new Address(userAddress)) : OptionalValue.newMissing()
            ]
            });
        
            const queryResponse = await networkProvider.queryContract(query);
        
            const resultsParser = new ResultsParser();
            const endpointDefinition = smartContract.getEndpoint('viewSaleInfo');
        
            const { firstValue } = resultsParser.parseQueryResponse(queryResponse, endpointDefinition);
    
            const info = firstValue?.valueOf();

            console.log(info);

            if(info.first_phase_started && !info.first_phase_ended) {
              setPrice(info.first_phase_price);
              setSold(info.first_phase_sold_count.toNumber());
            }

            if(info.second_phase_started && !info.second_phase_ended) {
              setPrice(info.second_phase_price);
              setSold(info.second_phase_sold_count.toNumber());
              setRemain(300 - info.first_phase_sold_count.toNumber());
            }

            setFirstPhaseStarted(info.first_phase_started);
            setFirstPhaseEnded(info.first_phase_ended);
            setSecondPhaseStarted(info.second_phase_started);
            setSecondPhaseEnded(info.second_phase_ended);

            // setMinted(info.purchased);
            setMinted(false);
        } catch(e) {
        }
    })();
  };
  
  const mint = () => {
    if(
      !userAddress ||
      !mintable
    ) {
        return; 
    }

    
    (async () => {
      if(firstPhaseStarted && !firstPhaseEnded) {
        let fire_nonce = 0;
        let water_nonce = 0;

        try {
          const { data } = await axios.get(`${apiAddress}/accounts/${userAddress}/nfts?collections=${FireRings}`);

          if(data.length) {
            fire_nonce = data[0].nonce;
          }
        } catch(e) {
        }

        try {
          const { data } = await axios.get(`${apiAddress}/accounts/${userAddress}/nfts?collections=${WaterRings}`);

          if(data.length) {
            water_nonce = data[0].nonce;
          }
        } catch(e) {
        }

        if(!fire_nonce) {
          toast.warn("You don't have FireRings.");
          return;
        }

        if(!water_nonce) {
          toast.warn("You don't have WaterRings.");
          return;
        }

        try {
          const userAccount = new Account(new Address(userAddress));

          const updatedUserAccount = await networkProvider.getAccount(
              new Address(userAddress)
          );

          userAccount.update(updatedUserAccount);

          // const tx = smartContract.methodsExplicit['firstPhaseMint']([])
          //     .withMultiESDTNFTTransfer(
          //       [
          //         TokenPayment.fungibleFromBigInteger(UsdcTokenId, price, 6),
          //         TokenPayment.nonFungible(FireRings, fire_nonce),
          //         TokenPayment.nonFungible(WaterRings, water_nonce),
          //       ],
          //       new Address(userAddress),
          //     )
          //     .withNonce(userAccount.nonce)
          //     .withGasLimit(30000000)
          //     .withChainID(isDev ? 'D' : '1');
          
          // const transaction = tx.buildTransaction();

          // await sendTransactions({
          //     transactions: [transaction],
          //     transactionsDisplayInfo: {
          //         processingMessage: 'Phase 1 Minting',
          //         errorMessage: 'Transaction Failed',
          //         successMessage: 'Transaction Success',
          //     },
          //     redirectAfterSign: false,
          // });
        } catch(e) {
        }
      } else {
        try {
          const userAccount = new Account(new Address(userAddress));

          const updatedUserAccount = await networkProvider.getAccount(
              new Address(userAddress)
          );

          userAccount.update(updatedUserAccount);

          const args: TypedValue[] = [
            new TokenIdentifierValue(UsdcTokenId),
            new BigUIntValue(price),
            new StringValue('secondPhaseMint'),
          ];
        
          const { argumentsString } = new ArgSerializer().valuesToString(args);
          const data = `ESDTTransfer@${argumentsString}`;
        
          const tx = {
            value: 0,
            data,
            receiver: contracts.SeedInvestorsSftMint.address,
            gasLimit: 30000000,
          };

          await sendTransactions({
              transactions: [tx],
              transactionsDisplayInfo: {
                  processingMessage: 'Phase 2 Minting',
                  errorMessage: 'Transaction Failed',
                  successMessage: 'Transaction Success',
              },
              redirectAfterSign: false,
          });
        } catch(e) {
        }
      }
    })();
  };
  
  return (
    <div className='d-flex flex-column flex-fill align-items-center container justify-content-center mb-5'>
      <div className="text-center color-primary mb-5" style={{ fontSize: '40px', fontWeight: '800'}}>Seed Investors</div>
      <div className='row'>
        <div className="col-md-6 d-flex justify-content-center align-items-center my-5">
          <video loop autoPlay style={{ width: '90%' }}>
            <source src={'./video/seedinvestors.mp4'} type="video/mp4" />
          </video>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-item-center">
            <h1 className="title text-center">
              <p>
                {
                  !firstPhaseStarted && (
                    'PHASE 1 SOON'
                  )
                }
                {
                  firstPhaseStarted && !firstPhaseEnded && (
                    'PHASE 1'
                  )
                }
                {
                  firstPhaseEnded && !secondPhaseStarted && (
                    'PHASE 2 SOON'
                  )
                }
                {
                  secondPhaseStarted && !secondPhaseEnded && (
                    'PHASE 2'
                  )
                }
                {
                  secondPhaseEnded && (
                    'SALE ENDED'
                  )
                }
              </p>
            </h1>
            
            {
              firstPhaseStarted && !firstPhaseEnded && (
                <h4 className="title text-white">
                  <p>{sold} / 100 SOLD</p>
                </h4>
              )
            }

            {
              secondPhaseStarted && !secondPhaseEnded && (
                <h4 className="title text-white">
                  <p>{sold} / {remain} SOLD</p>
                </h4>
              )
            }

            {
              firstPhaseStarted && !firstPhaseEnded && (
                <h4 className="title text-white mt-3">
                  <p>WaterRings & FireRings Required</p>
                </h4>
              )
            }
            {
              BigZero.lt(price) && (
                <h4 className="title text-white">
                  <p>Price: { shortenEsdt(convertWeiToEsdt(price, 6)) } USDC</p>
                </h4>
              )
            }
            {
              userAddress && (
                <h6 className='text-center text-white'>
                    Connected Address: {shortenAddress(userAddress)}
                </h6>
              )
            }
            {
              userAddress && (
                <h6 className="title text-white">
                  <p>Balance: { shortenEsdt(convertWeiToEsdt(usdcBalance, 6)) } USDC</p>
                </h6>
              )
            }
            {
              userAddress ? (
                <div className='text-center mt-4'>
                  <Button
                    style={{
                      width: 200,
                      background: '#fae447',
                      color: '#000',
                      border: 'none',
                    }}
                    disabled={!mintable}
                    onClick={() => mint()}
                  >
                    {
                      minted ? 'You already Minted' : 'Mint Now'
                    }
                  </Button>
                </div>
              ) : (
                <div className='text-center mt-4'>
                  <Button
                    style={{
                      width: 200,
                      background: '#fae447',
                      color: '#000',
                      border: 'none',
                    }}
                    onClick={() => navigate(routeNames.unlock)}
                  >
                    Connect Wallet
                  </Button>
                </div>
              )
            }
            {
              userAddress && (
                <div className='text-center mt-4'>
                  <Button
                    style={{
                      width: 200,
                      background: '#fae447',
                      color: '#000',
                      border: 'none',
                    }}

                    onClick={() => {
                      logout();

                      navigate(routeNames.unlock);
                    }}
                  >
                    Connect Another Wallet
                  </Button>
                </div>
              )
            }
        </div>
      </div>
    </div>
  );
};

export default SeedInvestors;
