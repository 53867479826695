/* eslint-disable import/order */
import * as React from 'react';
import { Row, Col, ProgressBar, Accordion } from 'react-bootstrap';
import "./index.scss";
import FadeInSection from 'components/animation/FadeInSection';

import ctpLogo from 'assets/img/seed-captain.png';
import brainstorm from 'assets/img/brainstorm.png';
import cryptoSeed from 'assets/img/crypto-seed.png';
import toTheMoon from 'assets/img/to-the-moon.png';
// import tempProject from 'assets/img/temp-project.png';
import linkPng from 'assets/img/link.png';
import unlinkPng from 'assets/img/unlink.png';
// import elrondSymbol from 'assets/img/elrond-symbol.svg';
import faqPng from 'assets/img/faq.gif';

import moonlorian from 'assets/img/partners/moonlorian.png';
import krogan_logo from 'assets/img/partners/Krogan.jpg';
import jex_logo from 'assets/img/partners/Jex.jpg';
import srb_logo from 'assets/img/partners/Srb.jpg';
import ecompass_logo from 'assets/img/partners/E-Compass.jpg';
import arda_logo from 'assets/img/partners/Arda.jpg';

import Zaya from 'assets/img/projects/zaya.jpeg';
import Game from 'assets/img/projects/icongame.png';
import Upgrade from 'assets/img/projects/upgrade.jpeg';

// import { DonutChart } from 'react-circle-chart';
import Marquee from "react-fast-marquee";
import { AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";
import {
  FaMediumM,
  // FaRedditAlien,
  // FaFacebookSquare,
  FaTwitter,
  FaTelegramPlane,
  FaDiscord
} from "react-icons/fa";

import Chart from "react-apexcharts";
import { HashLink } from 'react-router-hash-link';
import { routeNames } from 'routes';
import longinAvatar from 'assets/img/team/longin.png';
import petriAvatar from 'assets/img/team/petri.png';
import joseAvatar from 'assets/img/team/jose.png';
import sergiAvatar from 'assets/img/team/sergi.png';
import stancuAvatar from 'assets/img/team/stancu.png';
import adamAvatar from 'assets/img/team/adam.png';

const state: any = {
  series: [76.62012227352, 12.32987772648, 1, 1.5, 4, 0.3, 1.25, 3],
  options: {
    chart: {
      width: '100%',
      type: 'pie',
    },
    labels: ["Burn", "Community", "Liquidity Providing", "NFT Staking Rewards", "CTP Staking Rewards", "Marketing", "Advisors", "Team"],
    stroke: {
      show: false,
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#01a19f',
        shadeTo: 'dark'
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          offset: -5
        }
      }
    },
    tooltip: {
      enabled: true,
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '14px',
        fontWeight: '400'
      },
      formatter(val: any, opts: any) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val + '%'];
      }
    },
    legend: {
      show: false
    }
  },
};

const ctp_team = [
  {
    name: "Petri Marin-Voicu",
    role: 'Cofounder',
    avatar: petriAvatar,
    socials: {
      twitter: 'https://twitter.com/Petri_Mv?t=9SusZYaxEiJ4bA2-9UGXQw&s=08',
    }
  },
  {
    name: "Longin Voicescu",
    role: 'Cofounder',
    avatar: longinAvatar,
    socials: {
      linkedIn: 'https://www.linkedin.com/in/longin-voicescu-5b98b76a',
      twitter: 'https://twitter.com/VoicescuLongin',
    }
  },
  {
    name: "Adam Noaf",
    role: 'Community manager',
    avatar: adamAvatar,
    socials: {
      twitter: 'https://twitter.com/AaddaammNG?t=clPGWSKPo0cKFHoLhIx8zQ&s=09'
    }
  },
  {
    name: "Stancu Codrut",
    role: 'Ambassador',
    avatar: stancuAvatar,
    socials: {
      twitter: 'https://twitter.com/codrut_stancu'
    }
  },
  {
    name: "Jose F. Aznar",
    role: 'Advisor',
    avatar: joseAvatar,
    socials: {
      linkedIn: 'https://www.linkedin.com/in/joseaznarp/',
    }
  },
  {
    name: "Sergi Valero",
    role: 'Advisor',
    avatar: sergiAvatar,
    socials: {
      linkedIn: 'https://www.linkedin.com/in/sergi-valero-pujol-6809b818/'
    }
  }
];

// const tokenomics = [
//   {
//     "value": 30,
//     "label": "Ecosystem"
//   },
//   {
//     "value": 30,
//     "label": "Burn"
//   },
//   {
//     "value": 15,
//     "label": "Liquidity"
//   },
//   {
//     "value": 10,
//     "label": "Airdrop"
//   },
//   {
//     "value": 10,
//     "label": "Marketing & Dev"
//   },
//   {
//     "value": 5,
//     "label": "Team"
//   }
// ];

const partners = [
  {
    logo: krogan_logo,
    name: "Krogan"
  },
  {
    logo: jex_logo,
    name: "Jexchange"
  },
  {
    logo: srb_logo,
    name: "Super Rare Bears"
  },

  {
    logo: ecompass_logo,
    name: "E-Compass"
  },
  {
    logo: arda_logo,
    name: "Arda"
  },
  {
    logo: moonlorian,
    name: "Moonlorian"
  },
];
const Home = () => {

  return (
    <>
      <div className="home-section">
        <div className="ctp-container pt-4">
          <Row className="align-items-center">
            <Col md={6}>
              <div>
                <p className="text-white mb-4" style={{ fontSize: '60px', fontWeight: '800' }}>From an idea to a project</p>

                {/* <span className="text-primary" style={{ fontSize: '20px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"CTP wants to be the first meme coin in the Elrond blockchain that also has a purpose, namely to help protect the environment. We are currently in advanced discussions with international organizations to build partnerships to this effect. We also have proposals from our community in this regard."}</span> */}

                <div className="text-primary" style={{ fontSize: '20px' }}>
                  {`We help ideas to become projects. Seed Captain is the first incubator in the Elrond ecosystem, the place where quality ideas can find the necessary financing at the beginning of their journey. Seed Captain offers its community the possibility to invest and support projects in their earliest stages.`}
                </div>

                <div className="mt-5">
                  <HashLink to={routeNames.home + '#becomeaseedinvestor'}>
                    <button className="ctp-button mt-2 mr-2">Become a seed investor</button>
                  </HashLink>
                  <button className="ctp-button mt-2 mr-2" onClick={() => { window.open("https://forms.gle/jGxbCXiJJUnsRETw5"); }}>Submit your idea</button>
                  <button className="ctp-button mt-2" onClick={() => { window.open("litepaper.pdf"); }}>{'whitepaper'}</button>
                </div>

                {/* <div className="social-panel mt-5 d-flex">
                  <div className="d-flex align-items-center">
                    <div className="social-ico">
                      <img src={twitterLogo} />
                    </div>
                    <div className="ml-2">
                      <span className="text-primary">Follow: 20K</span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center ml-auto">
                    <div className="social-ico">
                      <img src={telegramLogo} />
                    </div>
                    <div className="ml-2">
                      <span className="text-primary">Follow: 20K</span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center ml-auto">
                    <div className="social-ico">
                      <img src={facebookLogo} />
                    </div>
                    <div className="ml-2">
                      <span className="text-primary">Follow: 20K</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
            <Col md={6}>
              <FadeInSection>
                <div className="p-5">
                  <img src={ctpLogo} style={{ width: '100%' }} />
                </div>
              </FadeInSection>
            </Col>
          </Row>
        </div>
      </div>
      <div className="section-padding ">
        <div className="pp-section">
          <div className="section-head">
            <h2 className="title" data-aos="fade-up" data-aos-delay="100" title="What and Why">
              <p className="section-title">About us</p>
            </h2>
          </div>
        </div>

        <div className="ctp-container section-padding pb-0">
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <img src={brainstorm} style={{ width: '100%' }} />
            </Col>
            <Col sm={6}>
              <div className="d-flex flex-column" style={{ fontSize: '17px' }}>
                <FadeInSection>
                  <div className="d-flex flex-column mt-4">
                    <span>
                      {`Over time throughout our communication with entrepreneurs, startups, and developers, we managed to identify "the missing puzzle" in the Elrond ecosystem.`}
                    </span>
                    <span className="mt-4">
                      {`Many good ideas emerge, but in order to secure funding (VCs, launchpads, etc.), teams must produce a MVP (Minimal Viable Product), a prototype that proves that their idea is viable. `}
                    </span>
                    <span className="mt-4">
                      {`Building this MVP requires funding and at this stage of the project funding is hard to acquire. Most of the time, investors who are able to fund projects at this stage are highly powerful investors (whales) who acquire the project's token at very low prices "in the early stages.`}
                    </span>
                  </div>
                </FadeInSection>
                <FadeInSection>
                  <div className="d-flex flex-column mt-4">
                    <span className="text-white" style={{ fontSize: '20px', fontWeight: '600' }}>
                      {`"The Captain" acts as a point of connection:`}
                    </span>
                    <span className="mt-4">
                      {`Facilitates obtaining seed funding for projects.`}
                    </span>
                    <span className="mt-4">
                      {`Assists small and medium-sized investors in banding together and acting like a giant investor (whale).`}
                    </span>
                    <span className="mt-4">
                      {`Keeping in mind Metcalfe's law, also helps the Elrond ecosystem.`}
                    </span>
                  </div>
                </FadeInSection>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="seed-investor-section" style={{ marginTop: '20px', marginBottom: '40px' }} id={"becomeaseedinvestor"}>
        <div className="ctp-container">
          <p className="section-title mt-3">How to become a seed investor</p>

          <Row className="align-items-center" style={{ rowGap: '20px', marginTop: '40px' }}>
            <Col sm={6}>
              <FadeInSection>
                <div className="seed-investor-guilde-line-step-left">
                  <span className="seed-investor-guilde-line-step-number">1</span>
                  <div className="d-flex flex-column ml-4" style={{ fontSize: '16px' }}>
                    <span>{"Purchase the NFT that allows you to invest in the incubated projects"}</span>
                    <span className="mt-2">{"Total supply - 350 NFT"}</span>
                  </div>
                </div>
              </FadeInSection>
              <FadeInSection>
                <div className="seed-investor-guilde-line-step-left mt-4">
                  <span className="seed-investor-guilde-line-step-number">2</span>
                  <span className="ml-4" style={{ fontSize: '16px' }}>
                    {"Stake your NFT"}
                  </span>
                </div>
              </FadeInSection>
              <FadeInSection>
                <div className="seed-investor-guilde-line-step-left mt-4">
                  <span className="seed-investor-guilde-line-step-number">3</span>
                  <span className="ml-4" style={{ fontSize: '16px' }}>
                    {"Stake $CTP in order to accumulate CIP points (Captain Incubator Points)"}
                  </span>
                </div>
              </FadeInSection>
            </Col>
            <Col sm={6}>
              <div className="d-flex justify-content-center">
                <img src={cryptoSeed} width={'80%'} />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="ctp-container">
        <div className="section-padding">
          <p className="section-title d-flex justify-content-end">Projects</p>

          <div className="future-projects-card" style={{ marginTop: '80px' }}>
            <Row className="align-items-center">
              <Col sm={8}>
                <Marquee gradient={false} speed={30} pauseOnClick={true}>
                  <a href='https://www.zaya.ai/' target="_blank" rel="noreferrer">
                    <div
                      className="project-card mr-4 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundImage: `url(${Zaya})`,
                      }}
                    >
                      {/* <div style={{ fontSize: '100px', fontWeight: '700' }}>?</div> */}
                    </div>
                  </a>

                  <a href='https://www.thecursedland.com/' target="_blank" rel="noreferrer">
                    <div
                      className="project-card mr-4 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundImage: `url(${Game})`,
                      }}
                    >
                      {/* <div style={{ fontSize: '100px', fontWeight: '700' }}>?</div> */}
                    </div>
                  </a>

                  <a href='https://upgradehealth.ai/' target="_blank" rel="noreferrer">
                    <div
                      className="project-card mr-4 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundImage: `url(${Upgrade})`,
                      }}
                    >
                      {/* <div style={{ fontSize: '100px', fontWeight: '700' }}>?</div> */}
                    </div>
                  </a>
                </Marquee>
              </Col>
              <Col sm={4}>
                <div>
                  <img className="w-100" src={toTheMoon} />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="section-padding">
          <p className="section-title">Incubate your idea</p>

          <Row style={{ rowGap: '20px', marginTop: '80px' }}>
            <Col md={6} lg={3}>
              <div className="incubate-guidline-step">
                <div className="incubate-guidline-step-number">1</div>
                <span>{"Apply to participate in the incubator"}</span>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="incubate-guidline-step">
                <div className="incubate-guidline-step-number">2</div>
                <span>{"Have preliminary conversations with the 1st assessment team"}</span>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="incubate-guidline-step">
                <div className="incubate-guidline-step-number">3</div>
                <span>{"Have preliminary discussions with the final board"}</span>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="incubate-guidline-step">
                <div className="incubate-guidline-step-number">4</div>
                <span>{"If our team deems the idea feasible, the project is incubated."}</span>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section-padding">
          <p className="section-title d-flex justify-content-center">Incubated projects</p>

          <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
            <span style={{ fontSize: '48px', fontWeight: '800', color: "#cccccc55" }}>Coming Soon</span>
          </div>
          {/* <Row style={{ marginTop: '80px', rowGap: '20px' }}>
            {
              [1, 1, 1, 1, 1, 1].map((_, index) => {
                return (
                  <Col sm={6} md={4} key={index}>
                    <FadeInSection>
                      <div className="incubated-project-card">
                        <img src={tempProject} className="w-100" />
                        <div className="p-4">
                          <div style={{ fontSize: '15px' }}>
                            {"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor orci enim, quis pretium sapien vulputate id. Quisque justo risus, pellentesque ac venenatis eget, sollicitudin at arcu. Sed porttitor malesuada ex non elementum."}
                          </div>
                        </div>
                      </div>
                    </FadeInSection>
                  </Col>
                );
              })
            }
          </Row> */}
        </div>

        <div className="section-padding">
          <p className="section-title">Initial Token Distribution</p>

          <Row className="mt-5 align-items-center" style={{ rowGap: '20px' }}>
            <Col md={7}>
              <span>CTP Token has been distributed as follows. You can also check token shares from sites like Elrondscan and Elrond Explorer.</span>

              <div className="d-flex flex-column mt-5">
                <span className="token-info">CTP Token (CTP)</span>
                <span className="mt-1">Token Name</span>
              </div>

              <Row>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">1,000,000,000</span>
                    <span className="mt-1">Token Supply (100%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={100} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">766,201,222.7352</span>
                    <span className="mt-1">Burn (76.62012227352%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={76.62012227352} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">123,298,777.2648</span>
                    <span className="mt-1">Community (12.32987772648%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={12.32987772648} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">10,000,000</span>
                    <span className="mt-1">Liquidity Providing (1%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={1} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">15,000,000</span>
                    <span className="mt-1">NFT Staking Rewards (1.5%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={1.5} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">40,000,000</span>
                    <span className="mt-1">CTP Staking Rewards (4%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={4} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">3,000,000</span>
                    <span className="mt-1">Marketing (0.3%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={0.3} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">12,500,000</span>
                    <span className="mt-1">Advisors(1.25%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={1.25} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="d-flex flex-column mt-5">
                    <span className="token-info">30,000,000</span>
                    <span className="mt-1">Team (3%)</span>
                    <ProgressBar className="ctp-customized-progressbar mt-4" now={3} />
                  </div>
                </Col>
              </Row>

              {/* <div className="mt-5">* Ecosystem wallet contains staking rewards and tokens to be distributed to the community.</div> */}
            </Col>
            <Col style={{ position: 'unset' }} md={5}>
              <div className="d-flex justify-content-center">
                <Chart options={state.options} series={state.series} type="pie" style={{ width: '100%' }} />
                {/* <DonutChart items={tokenomics} size={400} totalTextColor={"#FFFFFF"} /> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="big-burn-section my-5">
        <div className="ctp-container section-padding">
          <div className="pp-section">
            <div className="section-head">
              <h2 className="title" data-aos="fade-up" data-aos-delay="100" title="Biggest Burn">
                <p className="section-title">The Big Burn Plan</p>
              </h2>
            </div>
          </div>

          {/* <div className="d-flex justify-content-center mt-4" style={{ fontSize: '16px', color: 'gray' }}>
            CTP Token has been distributed as follows. You can also check token shares from sites like Elrondscan and Elrond Explorer.
          </div> */}

          <Row className="mt-5" style={{ rowGap: '20px' }}>
            <Col sm={6}>
              <div className="burn-phase hover-color-gold">
                <p>Buyback & Burn Phase</p>
                <div>
                  {`5% of the total funds raised for each incubated project will be used to buy back $CTP tokens available on CEXs or DEXs. The $CTP tokens thus purchased will be burned.`} <br />
                  {`* This phase will apply for each project incubated by Seed Captain without ending.`}
                </div>
              </div>
            </Col>

            <Col sm={6}>
              <div className="burn-phase hover-color-primary">
                <p>{`Bonus Burn Phase`}</p>
                <div>
                  {`A special wallet named "To be Burned Wallet” will be created and will contain approximately 80% of the current supply of $CTP tokens.`} <br />
                  {`From the "To Be Burned Wallet” will be burned 3 times more $CTP tokens than those burned in phase 1 after each incubated project.`} <br />
                  {`*The 2nd Phase of the burn will continue until there are no $CTP tokens in the "To Be Burned Wallet".`}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="ctp-container">
        <div className="section-padding">
          <p className="section-title d-flex justify-content-end">Roadmap</p>
          <span className="d-flex justify-content-end">* Participants do not have to apply for KYC! Our platform will complete the KYB and act as a sole investor.</span>

          <div style={{ marginTop: '60px' }}>
            <div className="roadmap-divider"></div>

            <div>
              <Row>
                <Col sm={6} md={3}>
                  <div className="d-flex flex-column p-4">
                    <div className="d-flex justify-content-center ">
                      <img src={linkPng} />
                    </div>

                    <div className="roadmap-phase mt-4 primary-border">
                      <p className="d-flex justify-content-center" style={{ fontSize: '24px', fontWeight: '800', margin: '20px 0px' }}>Q4-2022</p>
                      <span>
                        $CTP Tokenomics V2<br />
                        $CTP Staking Module V2 <br />
                        Website<br />
                        Litepaper<br />
                        Minting Fire NFT Collection<br />
                      </span>
                    </div>
                  </div>
                </Col>

                <Col sm={6} md={3}>
                  <div className="d-flex flex-column p-4">
                    <div className="d-flex justify-content-center ">
                      <img src={unlinkPng} />
                    </div>

                    <div className="roadmap-phase mt-4">
                      <p className="d-flex justify-content-center" style={{ fontSize: '24px', fontWeight: '800', margin: '20px 0px' }}>Q1-2023</p>
                      <span>
                        {`Minting Seed Captain NFTs Collection (100 NFTs )`}<br />
                        {`NFTs Staking Module V2 (Water, Fire, Seed)`}<br />
                        {`Whitepaper`} <br />
                        {`First incubated project`}<br />
                      </span>
                    </div>
                  </div>
                </Col>

                <Col sm={6} md={3}>
                  <div className="d-flex flex-column p-4">
                    <div className="d-flex justify-content-center ">
                      <img src={linkPng} />
                    </div>

                    <div className="roadmap-phase mt-4">
                      <p className="d-flex justify-content-center mt-4" style={{ fontSize: '24px', fontWeight: '800', margin: '20px 0px' }}>Q2-2023</p>
                      <span>
                        {`Dapp-v1`}<br />
                        {`Minting Seed Captain NFTs Collection (250 NFTs)`}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col sm={6} md={3}>
                  <div className="d-flex flex-column p-4">
                    <div className="d-flex justify-content-center ">
                      <img src={linkPng} />
                    </div>

                    <div className="roadmap-phase mt-4">
                      <p className="d-flex justify-content-center mt-4" style={{ fontSize: '24px', fontWeight: '800', margin: '20px 0px' }}>Q3-2023</p>
                      <span>
                        {`Dapp-v2`}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="team-section" style={{ marginTop: '80px' }}>
        <div className="pp-section">
          <div className="section-head">
            <h2 className="title" data-aos="fade-up" data-aos-delay="100" title="Tired System">
              <p className="section-title">CTP Team</p>
            </h2>
          </div>
        </div>

        <div className="ctp-container d-flex flex-column section-padding" style={{ rowGap: '30px' }}>

          <Row style={{ rowGap: '30px' }}>
            {
              ctp_team.map((row, index) => {
                return (
                  <Col md={6} key={index}>
                    <FadeInSection>
                      <div className="team-member">
                        <Row className="align-items-center" style={{ rowGap: '20px', width: '100%' }}>
                          <Col className="d-flex justify-content-center" sm={4}>
                            {
                              row.avatar ? <img className="photo" src={row.avatar} /> : <div className="photo" />
                            }

                          </Col>
                          <Col sm={8}>
                            <p className="name">{row.name}</p>
                            <p className="role">{row.role}</p>
                            <div className="social">
                              {
                                row.socials.linkedIn && (
                                  <a className="social-link-a profile-explorer-social" href={row.socials.linkedIn} target="blank">
                                    <AiFillLinkedin className="mr-2" />
                                  </a>
                                )
                              }
                              {
                                row.socials.twitter && (
                                  <a className="social-link-a profile-explorer-social" href={row.socials.twitter} target="blank">
                                    <AiFillTwitterCircle className="mr-2" />
                                  </a>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </FadeInSection>
                  </Col>
                );
              })

            }
          </Row>
        </div>
      </div >

      <div className="ctp-container">
        <div className="section-padding">
          <p className="section-title">Our Partners</p>

          <Marquee className="mt-5" gradient={false} speed={30} pauseOnClick={true}>
            {
              partners.map((row, index) => {
                return (
                  <div className="d-flex align-items-center mx-4" key={index}>
                    <div className="token-symbol">
                      <img src={row.logo} />
                    </div>
                    <span className="m-4" style={{ fontSize: '30px', fontWeight: '600' }}>{row.name}</span>
                  </div>
                );
              })
            }
          </Marquee>
        </div>

        <div className="section-padding">
          <div className="pp-section">
            <div className="section-head">
              <h2 className="title" data-aos="fade-up" data-aos-delay="100" title="Favourtie Quiz">
                <p className="section-title">FAQ</p>
              </h2>
            </div>
          </div>

          {/* <div className="d-flex justify-content-center mt-4" style={{ fontSize: '16px', color: 'gray' }}>The cryptocurrency market has seen an increase in volume in recent years, which is a great opportunity for new traders.</div> */}

          <Row className="d-flex align-items-center" style={{ marginTop: '40px', rowGap: '20px' }}>
            <Col sm={6}>
              <FadeInSection>
                <div className="custom-accordion">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {"What is an incubator?"}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {"An incubator is the place where teams with a very good project idea can get funding and advice and having this, they can create a functional prototype of their respective project. "}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {"What does seed investor mean?"}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div >
                          {"Seed investors are a type of angel investors who contribute with funds and provide other types of support during the first stages of the launch of a new business or business project."}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {"What is the difference between an incubator and a launchpad?"}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div >
                          {"The incubator is the first financing phase of a project. After the project’s idea goes through an incubator and gets funding for a functional prototype of the project, then the project can go to find other funding sources, such as launchpads."}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {"How does the entire process operate?"}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`•  Only one project will be incubated at a time `}</div>
                        <div>{`•  When a new project is announced, everyone's CIP score will be reset to zero and the accumulation of CIP points starts`} </div>
                        <div>{`* The CIP points will determine who will make up the top 350 investor`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What do CIP points represent?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`The CIP points are a ranking system calculated by Seed Captain’s Smart Contract, based on which the hierarchy of Seed investors is established.`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What are the steps to follow to accumulate CIP points?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`In order to accumulate CIP points and participate as a seed  investor in a project incubated by Seed Captain, you need to:`}</div>
                        <div>{`•  Step 1 (mandatory): Stake the Seed Captain NFT`}</div>
                        <div>{`•  Step 2 (mandatory): Stake $CTP tokens (can be staked in several tranches)
`}</div>
                        <div>{`•  Step 3 (optional): Stake 1 up to a max of 25 Water Rings NFTs
`}</div>
                        <div>{`•  Step 4 (optional: Stake 1 up to a max of 10 NFT Fire Rings NFT`}</div>
                        <div>{`* Accumulation of CIP points is a  daily process.`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What is the role of the $CTP token in the accumulation process of CIP points?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`To accumulate CIP points, a participant must first stake the Seed Captain NFT and then stake the $CTP tokens. The staked number of $CTP tokens, determines the number of accumulated CIP points.`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What is the role of Water Rings and Fire Rings NFTs collections?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`The role of these NFTs is to boost the accumulations of CIP points.
Each NFT of Water Rings collection will bring an extra 1% per day and each NFT of the Fire Rings collection will bring an extra 2% per day to the accumulated CIP points.`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`How many Seed Captain NFTs will there be available?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`There will be a maximum of 350 Seed Captain NFTs in total.`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`How many times the $CTP tokens can be added to stake in order to accumulate CIP points?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`A participant can add $CTP tokens to stake as many times as he wants. Each staked tranche has a 6 months locked period.
The unlock period of each tranche will be displayed by Seed Captain’s Smart Contract.
`}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What will be the allocation phases for an incubated project?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>{`There will be 3 allocation phases for each incubated project: `}</div>
                        <div>
                          {`•  1st phase - Guaranteed period`} <br />
                          {`There will be a guaranteed, equal allotment for the 1st 100 NFT holders according to CIP score. They will have 7 days to complete the transaction.`}
                        </div>
                        <div>
                          {`•  2nd phase - Ranking period`} <br />
                          {`TOP 100 CIPs holders have the chance to raise their allocation if there are any unsold tokens left in stage 1. FCFS requires a $1,000 minimum and a $5,000 maximum investment.`}
                        </div>
                        <div>
                          {`•  3rd phase - The waiting list phase`} <br />
                          {`Next 250 CIPs holders (position 101-350) have the possibility to: `} <br />
                          {`-  invest on FCFS basis if there are any unsold tokens after stages 1&2
or`} <br />
                          {`-  be added automatically to the project WL for its private sale if they haven't entered in stages 1&2`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What happens to those who have Seed Captain NFT and staked $CTP tokens but do not participate in the financing of the current project?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`Each holder of Seed Captain NFTs decides for himself, doing his own research, whether or not it is worth investing in a particular project. If he decides that the project incubated is not worth the investment, he is free not to invest. His CIP points, as well as the CIP points of all the other participants, will be reset to 0 at the announcement of a new project and will have the opportunity to invest in another project.`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>
            </Col>

            <Col sm={6}>
              <div className="faq-symbol">
                <img src={faqPng} style={{ width: '80%' }} />
              </div>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`How much will be the minimum and maximum investment for an incubated project?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`For each incubated project, the minimum investment and the maximum investment will be established when the project it’s announced.`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What happens if you remove the Seed Captain NFT from stake?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`When the Seed Captain NFT is removed from stake, the CIP points become 0 and the owner  will lose the chance to invest in the current project and will not be able to accumulate CIP points for future projects.`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`Are CIP points accumulated from one project to another?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`CIP points are reset to 0 for all participants when a new project is announced and they don’t accumulate from one project to another.`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`What is "The Big Burn Plan"?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`"The Big Burn Plan" is the plan according to which over 80% of the $CTP token supply will be burned.`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`How will “The Big Burn Plan” work?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`For each project incubated by Seed Captain, there will be a burn of $CTP tokens in 2 phases:`} <br />
                          {`• 1st Phase: 5% of the total funds raised for each incubated project will be used to buy back $CTP tokens (on CEXs or DEXs). The $CTP tokens thus purchased will be burned.`} <br />
                          {`• 2nd Phase: from a special wallet named "To be Burn Wallet” will be burned 3 times more $CTP tokens than those burned in phase 1. The "To be Burn Wallet” will contain approximately 80% of the current supply of $CTP tokens.`} <br />
                          {` *The 2nd Phase of the burn will continue as long as there are $CTP tokens in the "To be Burn Wallet".`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>

              <FadeInSection>
                <div className="custom-accordion mt-4">
                  <Accordion>
                    <Accordion.Item eventKey={"a"}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            {`How does the Seed Captain incubator help the Elrond ecosystem?`}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {`We hope that through Seed captain incubator many excellent ideas will reach the functional project phase. Thus, Seed Captain incubator will  help to increase the adoption of Elrond Blockchain, to increase the number of blockchain transactions and if we take into account Metcalfe's law, it will help increase the value of the Elrond blockchain!`}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </FadeInSection>
            </Col>
          </Row>

        </div>
      </div>

      <div className="section-padding" id="community">
        <div className="ctp-container" style={{ marginBottom: '80px' }}>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="d-flex justify-content-center">
                <div style={{ maxWidth: '500px' }}>
                  <p className="explore-community-title">
                    <span className="in-line__icon" />Explore the community
                  </p>

                  <div style={{ fontSize: '18px' }}>
                    {"Join the discussion on one of our many channels. Learn about the Seed Captain Incubator, meet others, and become an active voice in the space."}
                  </div>

                  <div className="d-flex">
                    <a className="social-link-a" href={'https://t.co/s7jaSi4aW2'} target="blank">
                      <div className="explore-social-icon">
                        <FaTelegramPlane />
                      </div>
                    </a>
                    {/* <div className="explore-social-icon">
                      <FaRedditAlien />
                    </div> */}
                    <a className="social-link-a" href={'https://discord.gg/CP5PrNWEzq'} target="blank">
                      <div className="explore-social-icon">
                        <FaDiscord />
                      </div>
                    </a>
                    <a className="social-link-a" href={'https://t.co/FwApbzv54S'} target="blank">
                      <div className="explore-social-icon">
                        <FaMediumM />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="d-flex flex-column align-items-center">
                {/* <FadeInSection>

                  <div className="explore-social-card">
                    <div className="d-flex align-items-center" style={{ fontSize: '36px' }}>
                      <FaFacebookSquare />
                      <span className="ml-3" style={{ fontWeight: '700' }}>Facebook</span>
                    </div>
                    <div className="mt-4" style={{ fontSize: '19px' }}>
                      Latest updates on innovations, features, and community highlights.
                    </div>
                  </div>
                </FadeInSection> */}

                <FadeInSection>
                  <a className="social-link-a" href={'https://twitter.com/seedcaptainX'} target="blank">
                    <div className="explore-social-card mt-3">
                      <div className="d-flex align-items-center" style={{ fontSize: '36px' }}>
                        <FaTwitter />
                        <span className="ml-3" style={{ fontWeight: '700' }}>Twitter</span>
                      </div>
                      <div className="mt-4" style={{ fontSize: '19px' }}>
                        Latest updates on innovations, features, and community highlights.
                      </div>
                    </div>
                  </a>
                </FadeInSection>
              </div>
            </Col>
          </Row>

        </div>

      </div>
    </>
  );
};

export default Home;