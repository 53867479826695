import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, InputAdornment, Modal, OutlinedInput, Checkbox } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { animated, useSpring } from 'react-spring';
import Logo from 'assets/img/incubator/zaya.png';
import EmailLogo from 'assets/img/incubator/email.png';
import FacebookLogo from 'assets/img/incubator/facebook.png';
import TwitterLogo from 'assets/img/incubator/twitter.png';
import WebLogo from 'assets/img/incubator/web.png';
import YoutubuLogo from 'assets/img/incubator/youtubu.png';
import './index.scss';
import { useGetAccountInfo, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Account, Address, AddressValue, ArgSerializer, BigUIntValue, ContractFunction, ResultsParser, SmartContract, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { contracts, InvestorNft, isDev, UsdcTokenId } from 'config';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import BigNumber from 'bignumber.js/bignumber';
import { convertEsdtToWei, convertWeiToEsdt, getDeltaTime, shortenEsdt } from 'utils';
import { toast } from 'react-toastify';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';

const BigZero = new BigNumber(0);

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: '#222121',
    // filter: 'blur(4px)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

export const Zaya = () => {
    const { account: { address: userAddress, balance } } = useGetAccountInfo();
    const { network: { apiAddress } } = useGetNetworkConfig();
    const networkProvider = new ProxyNetworkProvider(apiAddress, { timeout: 10000 });
    const { hasPendingTransactions } = useGetPendingTransactions();
    const isLoggedIn = useGetIsLoggedIn();
    const navigate = useNavigate();

    const contractAddress = new Address(contracts.ZayaVesting.address);
    const smartContract = new SmartContract({ address: contractAddress, abi: contracts.ZayaVesting.abi });

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const [salePrice, setSalePrice] = useState(BigZero);
    const [totalSoldAmount, setTotalSoldAmount] = useState(BigZero);
    const [saleStartTimestamp, setSaleStartTimestamp] = useState(0);
    const [saleEndTimestamp, setSaleEndTimestamp] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(BigZero);
    const [minUsdcAmount, setMinUsdcAmount] = useState(BigZero);
    const [maxUsdcAmount, setMaxUsdcAmount] = useState(BigZero);
    const [maxBuyAmount, setMaxBuyAmount] = useState(BigZero);

    const [userPaidUsdcAmount, setUserPaidUsdcAmount] = useState(BigZero);
    const [userBoughtAmount, setUserBoughtAmount] = useState(BigZero);
    const [userReleasedAmount, setUserReleasedAmount] = useState(BigZero);
    const [userWithdrawableAmount, setUserWithdrawableAmount] = useState(BigZero);
    const [saleCliffTimestamp, setSaleCliffTimestamp] = useState(0);
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    useEffect(() => {
        getSaleConfig();

        (async () => {
            if (!isLoggedIn) return;

            try {
                const { data } = await axios.get(`${apiAddress}/accounts/${userAddress}/tokens/${UsdcTokenId}`);

                setUsdcBalance(new BigNumber(data.balance));
            } catch (error) {
                console.log(error);
                setUsdcBalance(BigZero);
            }

            await getUserSaleInfo();
        })();
    }, [hasPendingTransactions]);

    const getSaleConfig = () => {
        (async () => {
            try {
                const query = smartContract.createQuery({
                    func: new ContractFunction('getSaleConfig'),
                    args: []
                });

                const queryResponse = await networkProvider.queryContract(query);

                const resultsParser = new ResultsParser();
                const endpointDefinition = smartContract.getEndpoint('getSaleConfig');

                const { firstValue } = resultsParser.parseQueryResponse(queryResponse, endpointDefinition);

                const value = firstValue?.valueOf();

                // console.log(value);
                setSalePrice(value.token_price);
                setTotalSoldAmount(value.total_sold_amount);
                setSaleStartTimestamp(value.sale_start_at);
                setSaleEndTimestamp(value.sale_end_at);
                setMinUsdcAmount(value.min_usdc_amount);
                setMaxUsdcAmount(value.max_usdc_amount);
                setMaxBuyAmount(value.max_buy_amount);

            } catch (error) {
                console.log(error);
            }
        })();
    };

    const getUserSaleInfo = async () => {
        try {
            const query = smartContract.createQuery({
                func: new ContractFunction('getUserSaleInfo'),
                args: [
                    new AddressValue(new Address(userAddress))
                ]
            });

            const queryResponse = await networkProvider.queryContract(query);

            const resultsParser = new ResultsParser();
            const endpointDefinition = smartContract.getEndpoint('getUserSaleInfo');

            const { firstValue } = resultsParser.parseQueryResponse(queryResponse, endpointDefinition);

            const value = firstValue?.valueOf();

            // console.log(value);
            setUserPaidUsdcAmount(value.user_paid_usdc_amount);
            setUserBoughtAmount(value.user_bought_amount);
            setUserReleasedAmount(value.user_released_amount);
            setUserWithdrawableAmount(value.user_withdrawable_amount);
            setSaleCliffTimestamp(value.cliff_timestamp.toNumber());
            setIsWhitelisted(value.is_whitelisted);
        } catch (error) {
            console.log(error);
        }
    };


    const [isPurchase, setIsPurchase] = useState<boolean>(true);
    const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
    const [purchaseAmount, setPurchaseAmount] = useState<string>('');

    const handleApply = () => {
        if (Date.now() < (saleStartTimestamp * 1000)) return;
        if (!isWhitelisted) {
            toast.warn("Whitelisted addresses can only apply to purchase token.");
            return;
        }
        setPurchaseAmount('');
        setShowPurchaseModal(true);
    };

    const handleInput = (value: string) => {
        setPurchaseAmount(value);
    };

    const handleMax = () => {
        handleInput(shortenEsdt(convertWeiToEsdt(usdcBalance, 6)));
    };

    const handelPurchase = async () => {
        try {
            if (!isLoggedIn) {
                toast.warn("Please connect wallet.");
                return;
            }

            if (!isChecked) {
                toast.warn("Please accept 'Terms of Sale and Use'");
                return;
            }

            if (usdcBalance.lte(BigZero)) {
                toast.warn("You don't have available USDC on your wallet.");
                return;
            }

            if (purchaseAmount == '' || new BigNumber(purchaseAmount).lte(BigZero) || convertWeiToEsdt(usdcBalance, 6).lt(purchaseAmount)) {
                toast.warn("Invalid purchase amount.");
                return;
            }

            if (convertEsdtToWei(purchaseAmount, 6).lt(minUsdcAmount)) {
                toast.warn(`You can buy token more than ${convertWeiToEsdt(minUsdcAmount, 6)}USDC`);
                return;
            }

            let _maxUsdcAmount = maxUsdcAmount;
            const availablePurchaseAmount = maxBuyAmount.minus(userPaidUsdcAmount);
            if (availablePurchaseAmount.lt(_maxUsdcAmount)) {
                _maxUsdcAmount = availablePurchaseAmount;
            }

            if (convertEsdtToWei(purchaseAmount, 6).gt(_maxUsdcAmount)) {
                toast.warn(`You can buy token less than ${convertWeiToEsdt(_maxUsdcAmount, 6)}USDC`);
                return;
            }

            const res = await axios.get<NftType[]>(`${apiAddress}/accounts/${userAddress}/nfts?collections=${InvestorNft}`);
            if (res.status == 200) {
                if (res.data.length === 0) {
                    toast.warn("You don't have Investor NFT on your wallet.");
                    return;
                }

                const userAccount = new Account(new Address(userAddress));

                const updatedUserAccount = await networkProvider.getAccount(
                    new Address(userAddress)
                );

                userAccount.update(updatedUserAccount);

                const args: TypedValue[] = [
                    new AddressValue(new Address(contracts.ZayaVesting.address)),
                    new U32Value(2),
                    new TokenIdentifierValue(UsdcTokenId),
                    new U64Value(0),
                    new BigUIntValue(convertEsdtToWei(purchaseAmount, 6)),
                    new TokenIdentifierValue(res.data[0].collection),
                    new U64Value(res.data[0].nonce),
                    new U64Value(1),
                    new StringValue('purchase'),
                ];

                const { argumentsString } = new ArgSerializer().valuesToString(args);
                const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

                const tx = new Transaction({
                    value: '0',
                    sender: new Address(userAddress),
                    receiver: new Address(userAddress),
                    gasLimit: 30000000,
                    chainID: isDev ? 'D' : '1',
                    data,
                });

                await sendTransactions({
                    transactions: [tx],
                    transactionsDisplayInfo: {
                        processingMessage: 'Purchase token',
                        errorMessage: 'Transaction Failed',
                        successMessage: 'Transaction Success',
                    },
                    redirectAfterSign: false,
                });
            }
            setShowPurchaseModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handelWithdraw = async () => {
        if (!isLoggedIn) {
            toast.warn("Please connect wallet.");
            return;
        }

        if (userWithdrawableAmount.lte(BigZero)) {
            toast.warn("You don't have available amount to withdraw.");
            return;
        }

        try {
            const userAccount = new Account(new Address(userAddress));

            const updatedUserAccount = await networkProvider.getAccount(
                new Address(userAddress)
            );

            userAccount.update(updatedUserAccount);
            const data = `withdraw`;
            const tx = {
                value: 0,
                data,
                // sender: new Address(userAddress),
                receiver: contracts.ZayaVesting.address,
                gasLimit: 30000000,
            };

            await sendTransactions({
                transactions: [tx],
                transactionsDisplayInfo: {
                    processingMessage: 'Withdraw unlocked token',
                    errorMessage: 'Transaction Failed',
                    successMessage: 'Transaction Success',
                },
                redirectAfterSign: false,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="incubator-section my-5">
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col md={12} className=''>
                            <div className='text-center'>
                                <div className="text-white" style={{ fontSize: '38px', fontWeight: '500', lineHeight: '120%' }}>Seed Captain Incubator</div>
                                <div className='mt-2' style={{ fontSize: '20px', fontWeight: '300', color: '#F5DA3B' }}>From an idea to a real project!</div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='my-5'>
                        <Col md={12} className='d-flex justify-content-center mt-3'>
                            {
                                isWhitelisted ? (
                                    <div className={`action-button mr-1`}>
                                        You passed the KYC
                                    </div>
                                ) : (
                                    <a href='https://zaya.synaps.me/signup' target='_blank'>
                                        <div className={`action-button mr-1`}>
                                            Apply for KYC
                                        </div>
                                    </a>
                                )
                            }
                            <div className={`action-button ml-1 mr-1 ${isPurchase ? 'action-active-button' : ''}`} onClick={() => setIsPurchase(!isPurchase)}>
                                Purchase
                            </div>
                            <div className={`action-button ml-1 ${!isPurchase ? 'action-active-button' : ''}`} onClick={() => setIsPurchase(!isPurchase)}>
                                Vesting
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '110px' }} className='mb-5'>
                        <Col md={12} className='d-flex justify-content-center'>
                            <div className='project-container'>
                                <div className='project-logo'>
                                    <img src={Logo} alt='zaya'></img>
                                </div>
                                <div className='project-name'>ZayaAI</div>
                                <div className='project-description'>ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.</div>
                                {
                                    isPurchase ? (
                                        <>
                                            <div className='project-cap mt-4'>
                                                <div>Price per token</div>
                                                <div className='project-cap-value'>${shortenEsdt(convertWeiToEsdt(salePrice, 6), 4)} USD</div>
                                            </div>
                                            <div className='project-cap mt-1'>
                                                <div>Raised in Public Sale</div>
                                                <div className='project-cap-value'>${shortenEsdt(convertWeiToEsdt(totalSoldAmount.multipliedBy(convertWeiToEsdt(salePrice, 6)), 18))} USD</div>
                                            </div>
                                            <div className='project-cap mt-1'>
                                                <div>Offered tokens</div>
                                                <div className='project-cap-value'>3,000,000 ZAY</div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='project-cap mt-4'>
                                                <div>Your tokens</div>
                                                <div className='project-cap-value'>{shortenEsdt(convertWeiToEsdt(userBoughtAmount, 18))} ZAY</div>
                                            </div>
                                            <div className='project-cap mt-1'>
                                                <div>Released</div>
                                                <div className='project-cap-value'>{shortenEsdt(convertWeiToEsdt(userReleasedAmount, 18))} ZAY</div>
                                            </div>
                                            <div className='project-cap mt-1'>
                                                <div>Locked</div>
                                                <div className='project-cap-value'>{shortenEsdt(convertWeiToEsdt(userBoughtAmount.minus(userReleasedAmount).minus(userWithdrawableAmount), 18))} ZAY</div>
                                            </div>
                                            <div className='project-cap mt-1'>
                                                <div>Unlocking</div>
                                                <div className='project-cap-value'>{shortenEsdt(convertWeiToEsdt(userWithdrawableAmount, 18))} ZAY</div>
                                            </div>
                                        </>
                                    )
                                }
                                <div className='project-social mt-4'>
                                    <div className='text-center'>Social</div>
                                    <div className='project-socal-logos mt-2'>
                                        <a href='https://www.facebook.com/zaya.artificial.intelligence' target='_blank'><img className='mx-2' src={FacebookLogo} alt='facebook' /></a>
                                        <a href='https://twitter.com/ZayaAI_PathDx' target='_blank'><img className='mx-2' src={TwitterLogo} alt='twitter' /></a>
                                        <a href='https://www.zaya.ai' target='_blank'><img className='mx-2' src={WebLogo} alt='web' /></a>
                                        <a href='contact@zaya.ai' target='_blank'><img className='mx-2' src={EmailLogo} alt='email' /></a>
                                        <a href='https://www.youtube.com/@zayaai' target='_blank'><img className='mx-2' src={YoutubuLogo} alt='youtubu' /></a>
                                    </div>
                                </div>
                                {
                                    isPurchase ? (
                                        Date.now() > saleEndTimestamp * 1000 ? (
                                            <div className='project-apply'>
                                                <div>Ended</div>
                                            </div>
                                        ) : (
                                            <div className='project-apply' onClick={handleApply}>
                                                <div>Apply</div>
                                                {saleStartTimestamp > (Date.now() / 1000) && (
                                                    <div>{`(in ${getDeltaTime(saleStartTimestamp)})`}</div>
                                                )}
                                            </div>
                                        )
                                    ) : (
                                        <div className='project-apply project-unlock' onClick={handelWithdraw}>
                                            <div>Unlock</div>
                                            {userBoughtAmount.gt(BigZero) && saleCliffTimestamp > (Date.now() / 1000) && (
                                                <div>{`(in ${getDeltaTime(saleCliffTimestamp)})`}</div>
                                            )}
                                        </div>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showPurchaseModal}
                onClose={() => setShowPurchaseModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showPurchaseModal}>
                    <Box sx={fadeBoxStyle} className='purchase-modal'>
                        <div className='d-flex'>
                            How much would you like to purchase?
                        </div>
                        <div className='d-flex mt-2'>balance: {shortenEsdt(convertWeiToEsdt(usdcBalance, 6))} USDC</div>
                        <OutlinedInput
                            className='mt-1'
                            id="outlined-adornment-weight"
                            endAdornment={<InputAdornment position="end"><div className='text-white' style={{ cursor: 'pointer' }} onClick={handleMax}>Max</div></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            placeholder='Plese input amount'
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            sx={{
                                height: '35px',
                                width: '100%',
                                border: '1px solid white',
                                color: 'white'
                            }}
                            type='number'
                            value={purchaseAmount}
                            onChange={(e: any) => handleInput(e.target.value)}
                        />
                        <div className='d-flex mt-3 justify-content-center'>{`You will get ${new BigNumber(purchaseAmount).gt(BigZero) ? new BigNumber(purchaseAmount).dividedBy(convertWeiToEsdt(salePrice, 6)) : '0'} ZAY`}</div>
                        <div className='d-flex mt-3'>
                            <Checkbox
                                checked={isChecked}
                                onChange={(e: any) => setIsChecked(e.target.checked)}
                                style={{
                                    color: 'white',
                                    padding: '0px 0px',
                                    marginRight: '5px'
                                }}
                            />
                            <a className='d-flex' style={{ cursor: 'pointer' }} href='https://drive.google.com/file/d/1-8Z_gWShDcNa6Yke47QTjtVMwaqc7gjC/view?usp=sharing' target='_blank'>
                                <div>I agree to Terms of Sale and Use</div>
                                <ArrowForwardIcon />
                            </a>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='purchase-button' onClick={handelPurchase}>
                                Purchase
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};
