import BigNumber from 'bignumber.js/bignumber';

export function getCurrentTimestamp() {
    return (new Date()).getTime();
}

export const paddingTwoDigits = (num: any) => {
    return num.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
};

export const shortenAddress = (address: string) => {
    if(address.length) {
          return address.substring(0, 10) + '...' + address.substring(address.length - 10);
    } else {
        return '';
    }
};

export const convertWeiToEsdt = (v: any, decimals = 18): BigNumber => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const result = value.dividedBy(new BigNumber(10 ** decimals));

    return result;
};

export const convertEsdtToWei = (amount: BigNumber.Value, decimals?: number): BigNumber => {
    if (!amount) amount = '0';
    return new BigNumber(amount).shiftedBy(decimals == null ? 18 : decimals).decimalPlaces(0, BigNumber.ROUND_FLOOR);
};

export const shortenEsdt = (v: any, precision = 2): string => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const number = +value.toFixed(precision, BigNumber.ROUND_FLOOR);

    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: precision }).format(number);
};

export const getDeltaTime = (timestamp: number) => {

    let delta = timestamp - Date.now() / 1000;
    let years = 0;
    let weeks = 0;
    const months = Math.floor(delta / 2592000);
    if(months < 1) {
      weeks = Math.floor(delta / 604800);
    }
    if(months >= 12) {
      years = Math.floor(months / 12);
    }
    delta -= months * 2592000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
  
    if (years > 0) {
      let txt = ' years';
      if (years > 1) txt = ' year'; 
      return Math.floor(years) + txt;
    } else if (months > 0) {
      let txt = ' month';
      if (months > 1) txt = ' months'; 
      return Math.floor(months) + txt;
    } else if (weeks > 0) {
      let txt = ' week';
      if (weeks > 1) txt = ' weeks'; 
      return Math.floor(weeks) + txt;
    } else if (days > 0) {
      let txt = ' day';
      if (days > 1) txt = ' days'; 
      return Math.floor(days) + txt;
    } else if (hours > 0) {
      let txt = ' hour';
      if (hours > 1) txt = ' hours'; 
      return Math.floor(hours) + txt;
    } else {
      let txt = ' minute';
      if (minutes > 1) txt = ' minutes'; 
      return Math.floor(minutes) + txt;
    }
  };
